import { rem } from 'polished'
import PropTypes from 'prop-types'
import { DYNAMIC_ROUTE, ROUTE } from 'constants/routes'
import {
  formatAdminDashboardTitle,
  formatClientDashboardTitle,
  formatExpertDashboardTitle,
  formatPageTitleWithDash,
} from 'utils/formatTitle'
import { WEBSITE_TITLE } from './seo'

export const PUBLIC_SESSION_DURATION_DIFFERENCE_IN_MINUTES = 5
export const NUMBER_OF_AVAILABLE_WEEKS_IN_FUTURE = 12
export const SESSION_FULL_REFUND_LIMIT_IN_HOURS = 48
export const ALLOWED_HOURS_TO_BOOK_SESSION = 24
export const ALLOWED_HOURS_TO_UPDATE_ATTACHMENTS = 0

export const HEADER_HEIGHT_VALUES = {
  MOBILE_PARTIAL: 60,
  MOBILE_FULL: 120,
  DESKTOP: 70,
}
export const HEADER_HEIGHT_MOBILE_PARTIAL = rem(
  HEADER_HEIGHT_VALUES.MOBILE_PARTIAL
)
export const HEADER_HEIGHT_MOBILE_FULL = rem(HEADER_HEIGHT_VALUES.MOBILE_FULL)
export const HEADER_HEIGHT_DESKTOP = rem(HEADER_HEIGHT_VALUES.DESKTOP)

export const WHITESPACE_REGEX_PATTERN = /\s/g
export const CONTAINS_LETTERS_REGEX_PATTERN = /[a-z]/i
export const VALID_URL_SLUG_PATTERN = /^[a-z0-9-]*[a-z]+[a-z0-9-]*$/i
export const EXPERT_SESSION_MINIMAL_PRICE = 1

export const PASSWORD_VALIDATION = {
  PATTERN: /^(?=.*[A-Za-z])(?=.*\d)([A-Za-z\d]|[^a-zA-Z0-9]){8,}$/,
  MESSAGE: 'Password must include at least one letter and number',
}

export const RECAPTCHA_CONTAINER_ID = 'recaptcha-container'

const pathnameToTitle = {
  // public routes
  [ROUTE.FRONT_PAGE()]: formatPageTitleWithDash('Home'),
  [ROUTE.SIGN_IN()]: formatPageTitleWithDash('Sign in'),
  [ROUTE.SIGN_UP()]: formatPageTitleWithDash('Sign up'),
  [ROUTE.EXPERTS()]: formatPageTitleWithDash('Experts'),
  [ROUTE.FAQ()]: formatPageTitleWithDash('FAQ'),
  [ROUTE.BLOG_LIST()]: formatPageTitleWithDash('Blog'),
  [ROUTE.PRIVACY_POLICY()]: formatPageTitleWithDash('Privacy policy'),
  [ROUTE.RESET_PASSWORD()]: formatPageTitleWithDash('Reset password'),
  [ROUTE.SUPPORT()]: formatPageTitleWithDash('Support'),
  [ROUTE.TERMS_OF_USE()]: formatPageTitleWithDash('Terms of use'),
  [ROUTE.DOCS()]: formatPageTitleWithDash('Docs'),
  [ROUTE.SHOWROOM()]: formatPageTitleWithDash('Shop'),
  [ROUTE.SHOWROOM_PRODUCTS()]: formatPageTitleWithDash('Shop all products'),
  [ROUTE.SHOWROOM_CART()]: formatPageTitleWithDash('Shop cart'),
  [ROUTE.SHOWROOM_CHECKOUT()]: formatPageTitleWithDash('Shop checkout'),
  [ROUTE.SHOWROOM_CHECKOUT_CONTACT()]: formatPageTitleWithDash(
    'Shop checkout',
    'Contact'
  ),
  [ROUTE.SHOWROOM_CHECKOUT_SHIPPING()]: formatPageTitleWithDash(
    'Shop checkout',
    'Shipping'
  ),
  [ROUTE.ORDER_CONFIRMATION()]: formatPageTitleWithDash(
    'Shop checkout',
    'Confirmation'
  ),
  [ROUTE.COUPON_CHECKOUT()]: formatPageTitleWithDash('Coupon checkout'),
  [ROUTE.COUPON_CHECKOUT_THANK_YOU_PAGE()]: formatPageTitleWithDash(
    'Coupon checkout',
    'Confirmation'
  ),
  [DYNAMIC_ROUTE.SESSION_CHECKOUT()]:
    formatPageTitleWithDash('Session checkout'),
  [DYNAMIC_ROUTE.CLIENT_SESSION_QUESTIONNAIRE()]: formatPageTitleWithDash(
    'Session checkout',
    'Questionnaire'
  ),
  [DYNAMIC_ROUTE.PURCHASE_CHECKOUT()]:
    formatPageTitleWithDash('Purchase checkout'),
  [ROUTE.PURCHASE_CHECKOUT_THANK_YOU_PAGE()]: formatPageTitleWithDash(
    'Purchase checkout',
    'Confirmation'
  ),

  // client routes
  [ROUTE.CLIENT()]: formatClientDashboardTitle('Upcoming sessions'),
  [ROUTE.CLIENT_SESSIONS_PAST()]: formatClientDashboardTitle('Past sessions'),
  [ROUTE.CLIENT_FAVORITE_EXPERTS()]:
    formatClientDashboardTitle('Saved experts'),
  [ROUTE.CLIENT_PURCHASES()]: formatClientDashboardTitle('Purchases'),
  [ROUTE.CLIENT_SETTINGS_ACCOUNT()]:
    formatClientDashboardTitle('Account settings'),
  [ROUTE.CLIENT_SETTINGS_PASSWORD()]:
    formatClientDashboardTitle('Password settings'),
  [ROUTE.CLIENT_SETTINGS_PAYMENT()]:
    formatClientDashboardTitle('Billing settings'),
  [ROUTE.CLIENT_SETTINGS_INVOICES()]:
    formatClientDashboardTitle('Invoices settings'),
  [ROUTE.CLIENT_SETTINGS_ADDRESSES()]:
    formatClientDashboardTitle('Addresses settings'),

  // expert routes
  [ROUTE.EXPERT_DASHBOARD()]: formatExpertDashboardTitle('Dashboard'),
  [ROUTE.EXPERT_SESSIONS()]: formatExpertDashboardTitle('Past consultations'),
  [ROUTE.EXPERT_CALENDAR_AVAILABILITY()]:
    formatExpertDashboardTitle('Availability'),
  [ROUTE.EXPERT_CALENDAR_UPCOMING()]:
    formatExpertDashboardTitle('Upcoming sessions'),
  [ROUTE.EXPERT_CALENDAR_PAST()]: formatExpertDashboardTitle('Past sessions'),
  [ROUTE.EXPERT_CLIENTS()]: formatExpertDashboardTitle('Clients'),
  [ROUTE.EXPERT_PURCHASES()]: formatExpertDashboardTitle('Commissions'),
  [ROUTE.EXPERT_CLIENTS()]: formatExpertDashboardTitle('Clients'),
  [ROUTE.EXPERT_COMMISSIONS()]: formatExpertDashboardTitle('Commissions'),
  [ROUTE.EXPERT_TEAM()]: formatExpertDashboardTitle('Team'),
  [ROUTE.EXPERT_SETTINGS_PROFILE()]:
    formatExpertDashboardTitle('Profile settings'),
  [ROUTE.EXPERT_SETTINGS_PAYMENT_OVERVIEW()]:
    formatExpertDashboardTitle('Payment settings'),
  [ROUTE.EXPERT_SETTINGS_ACCOUNT()]:
    formatExpertDashboardTitle('Account settings'),
  [ROUTE.EXPERT_SETTINGS_PASSWORD()]:
    formatExpertDashboardTitle('Password settings'),
  [DYNAMIC_ROUTE.EXPERT_SESSIONS_PRIVATE_FORM()]:
    formatExpertDashboardTitle('Private session'),
  [DYNAMIC_ROUTE.EXPERT_SESSIONS_GROUP_FORM()]:
    formatExpertDashboardTitle('Group session'),

  // admin routes
  [ROUTE.ADMIN()]: formatPageTitleWithDash('Admin'),
  [ROUTE.ADMIN_SIGN_IN()]: formatAdminDashboardTitle('Sign in'),
  [ROUTE.ADMIN_CLIENTS()]: formatAdminDashboardTitle('Clients'),
  [DYNAMIC_ROUTE.ADMIN_CLIENT_DETAIL()]:
    formatAdminDashboardTitle('Client detail'),
  [ROUTE.ADMIN_ADMINS()]: formatAdminDashboardTitle('Admins'),
  [ROUTE.ADMIN_CATEGORIES()]: formatAdminDashboardTitle('Categories'),
  [ROUTE.ADMIN_EXPERT_APPLICATIONS()]: formatAdminDashboardTitle(
    'Expert applications'
  ),
  [ROUTE.ADMIN_EXPERT_APPLICATIONS()]: formatAdminDashboardTitle(
    'Expert trade applications'
  ),
  [DYNAMIC_ROUTE.ADMIN_EXPERT_APPLICATION_DETAIL()]: formatAdminDashboardTitle(
    'Expert application detail'
  ),
  [DYNAMIC_ROUTE.ADMIN_EXPERT_APPLICATION_DETAIL()]: formatAdminDashboardTitle(
    'Expert trade application detail'
  ),
  [ROUTE.ADMIN_ALL_EXPERT_USERS()]: formatAdminDashboardTitle('All members'),
  [ROUTE.ADMIN_TRADE_EXPERT_USERS()]:
    formatAdminDashboardTitle('Trade members'),
  [ROUTE.ADMIN_TRADE_EXPERTS()]: formatAdminDashboardTitle('Trade firms'),
  [ROUTE.ADMIN_EXPERTS()]: formatAdminDashboardTitle('Experts'),
  [ROUTE.ADMIN_TRADE_LICENSES()]: formatAdminDashboardTitle('Trade licenses'),
  [DYNAMIC_ROUTE.ADMIN_EXPERT_DETAIL()]:
    formatAdminDashboardTitle('Expert detail'),
  [ROUTE.ADMIN_FEATURED_EXPERTS()]:
    formatAdminDashboardTitle('Featured Experts'),
  [ROUTE.ADMIN_SESSIONS()]: formatAdminDashboardTitle('Sessions'),
  [DYNAMIC_ROUTE.ADMIN_SESSION_DETAIL()]:
    formatAdminDashboardTitle('Session detail'),
  [ROUTE.ADMIN_SESSION_LOGS()]: formatAdminDashboardTitle('Session logs'),
  [ROUTE.ADMIN_REGIONS()]: formatAdminDashboardTitle('Regions'),
  [ROUTE.ADMIN_PRODUCTS()]: formatAdminDashboardTitle('Products'),
  [ROUTE.ADMIN_MANUAL_COLLECTIONS()]:
    formatAdminDashboardTitle('Manual collections'),
  [ROUTE.ADMIN_AUTO_COLLECTIONS()]:
    formatAdminDashboardTitle('Auto collections'),
  [ROUTE.ADMIN_EXPERT_COLLECTIONS()]:
    formatAdminDashboardTitle('Expert collections'),
  [ROUTE.ADMIN_VENDORS()]: formatAdminDashboardTitle('Vendors'),
  [ROUTE.ADMIN_PURCHASES()]: formatAdminDashboardTitle('Custom orders'),
  [ROUTE.ADMIN_ORDERS()]: formatAdminDashboardTitle('Showroom orders'),
  [ROUTE.ADMIN_COUPONS()]: formatAdminDashboardTitle('Coupons'),
  [ROUTE.ADMIN_CMS_LANDING_PAGE()]: formatAdminDashboardTitle(
    'Website content',
    'Home page'
  ),
  [ROUTE.ADMIN_SHOWROOMS()]: formatAdminDashboardTitle(
    'Website content',
    'Store pages'
  ),
  [ROUTE.ADMIN_CATEGORIES()]: formatAdminDashboardTitle(
    'Website content',
    'Category pages'
  ),
  [ROUTE.ADMIN_SHOWROOM_NAVIGATION()]: formatAdminDashboardTitle(
    'Website content',
    'Store navigation'
  ),
  [ROUTE.ADMIN_LISTING_WIDGETS()]: formatAdminDashboardTitle(
    'Website content',
    'Listing widgets'
  ),
  [ROUTE.ADMIN_CMS_ARTICLES()]: formatAdminDashboardTitle(
    'Website content',
    'Articles'
  ),
  [DYNAMIC_ROUTE.ADMIN_CMS_ARTICLE_PAGE()]: formatAdminDashboardTitle(
    'Website content',
    'Article'
  ),
  [ROUTE.ADMIN_TRUSTED_SOURCES()]: formatAdminDashboardTitle(
    'Website content',
    'Trusted sources'
  ),
  [ROUTE.TRADE()]: formatPageTitleWithDash('Trade Program'),
}

export const mapPathnameToTitle = (pathname: string) =>
  pathnameToTitle[pathname] || WEBSITE_TITLE

export const GRAPHQL_ERROR = {
  AUTH_ERROR: 'AuthError',
  INTERNAL_ERROR: 'InternalError',
  PROMO_CODE_ERROR: 'PromoCodeError',
  COUPON_VALIDATION_ERROR: 'CouponValidationError',
}

export const SESSION_TYPE = {
  PRIVATE: 'PRIVATE',
  GROUP: 'GROUP',
} as const

export const CLIENT_ADDRESS_TYPES = {
  BILLING: 'BILLING',
  SHIPPING: 'SHIPPING',
}

export const EXPERT_USER_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  SUSPENDED: 'SUSPENDED',
}

export const SESSION_STATUSES = {
  PENDING: 'PENDING',
  CANCELLED: 'CANCELLED',
  FINISHED: 'FINISHED',
  ON_HOLD: 'ON_HOLD',
}

export const TEXT_EDITOR_FORMAT = {
  HTML: 'html',
  MARKDOWN: 'markdown',
}

export const DEFAULT_CURRENCY_ISO_CODE = 'USD'

export const SORTER_DIRECTION = {
  ASC: 'ASC',
  DESC: 'DESC',
}

export const COLLECTION_SORTER_FIELDS = {
  POSITION_INDEX_WEIGHT: 'positionIndexWeight',
  PRICE: 'minPublishedPrice',
  FIRST_PUBLISHED_AT_UTC: 'firstPublishedAtUtc',
}

export const SESSION_CLIENT_STATUSES = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
  CANCELLED: 'CANCELLED',
}

export const SESSION_REPORT_ITEM_TYPES = {
  TRADE_ONLY: 'TRADE_ONLY',
  DIRECT_TO_CONSUMER: 'DIRECT_TO_CONSUMER',
}

export const REVENUE_DATE_GROUP_TYPES = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  YEAR: 'YEAR',
}

export const REVENUE_PERIOD_STATES = {
  ALL_TIME: 'ALL_TIME',
  LAST_MONTH: 'LAST_MONTH',
  THIS_MONTH: 'THIS_MONTH',
}

export const ZOOM_ACCOUNT_TYPE = {
  BASIC: 'BASIC',
  LICENCED: 'LICENCED',
  ON_PREM: 'ON_PREM',
  NONE: 'NONE',
}

export const CURRENCY_ISO_CODE = {
  USD: 'USD',
  CAD: 'CAD',
  GBP: 'GBP',
  EUR: 'EUR',
} as const

export type CurrencyIsoCode =
  (typeof CURRENCY_ISO_CODE)[keyof typeof CURRENCY_ISO_CODE]

export const EXPERTS_LISTING_WIDGET_LIMIT = 16

export const ARTICLES_WIDGET_LIMIT = {
  DESKTOP: 6,
  MOBILE: 3,
} as const

export const COLLECTION_WIDGET_LIMIT = {
  DESKTOP: 30,
  MOBILE: 30,
} as const

export const MULTI_COLLECTION_WIDGET_LIMIT = {
  TABLET: 33,
  DEFAULT: 32,
} as const

export const CATEGORY_HEADER_WIDGET_TYPE = {
  SINGLE_IMAGE: 'SINGLE_IMAGE',
  CAROUSEL: 'CAROUSEL',
} as const

// TODO: Remove
export const ALPHABETICAL_INDEX_WIDGET_TYPE = {
  BRAND: 'BRAND',
} as const

export const PURCHASE_STATUS = {
  DRAFT: 'DRAFT',
  PENDING: 'PENDING',
  PAID: 'PAID',
  PROCESSING: 'PROCESSING',
  IN_PRODUCTION: 'IN_PRODUCTION',
  SHIPPED: 'SHIPPED',
  DELIVERED: 'DELIVERED',
  REFUNDED: 'REFUNDED',
  VOID: 'VOID',
} as const

export type PurchaseStatus =
  (typeof PURCHASE_STATUS)[keyof typeof PURCHASE_STATUS]

export const NEW_PURCHASE_ID = 'new'

export const LISTING_WIDGET_TYPES = {
  INFO: 'INFO',
  HELP: 'HELP',
  QUOTATION: 'QUOTATION',
  CONSULTATION: 'CONSULTATION',
} as const

export const MULTI_COLLECTION_WIDGET_TYPE = {
  DEFAULT: 'DEFAULT',
  FEATURED: 'FEATURED',
} as const

export const WIDGET_PLACEMENT = {
  HERO: 'HERO',
  SECTION: 'SECTION',
} as const

export const LISTING_WIDGET_TYPE_NAMES = {
  [LISTING_WIDGET_TYPES.INFO]: 'Info',
  [LISTING_WIDGET_TYPES.CONSULTATION]: 'Consultation',
  [LISTING_WIDGET_TYPES.HELP]: 'Help',
  [LISTING_WIDGET_TYPES.QUOTATION]: 'Quotation',
} as const

export const COLLECTION_HEADER_WIDGET_TYPE = {
  DEFAULT: 'DEFAULT',
  WHITE_TEXT_ON_DARK: 'WHITE_TEXT_ON_DARK',
} as const

export const HOME_PAGE_SLUG = 'home'
export const CAREERS_PAGE_SLUG = 'careers'
export const TERMS_PAGE_SLUG = 'terms'
export const FAQ_PAGE_SLUG = 'faq'
export const BLOG_PAGE_SLUG = 'blog'
export const SHOWROOM_PAGE_SLUG = 'shop'
export const CONSULTATIONS_PAGE_SLUG = 'consultations'
export const FIND_AN_EXPERT_SLUG = 'consultations/find-an-expert'
export const SHOWROOM_BRANDS_PAGE_SLUG = 'shop/brands'
export const EXPERT_SHOWROOMS_PAGE_SLUG = 'expert-showrooms'
export const PRODUCTS_PAGE_SLUG = 'products'

export const STATIC_SHOWROOM_PAGE_SLUGS = [
  SHOWROOM_PAGE_SLUG,
  SHOWROOM_BRANDS_PAGE_SLUG,
  EXPERT_SHOWROOMS_PAGE_SLUG,
  PRODUCTS_PAGE_SLUG,
] as const

export const REVALIDATE_INTERVAL_IN_SECONDS =
  process.env.NEXT_PUBLIC_NODE_ENV === 'production' ? 10 : 1

export const PRODUCT_REVALIDATE_INTERVAL_IN_SECONDS =
  process.env.NEXT_PUBLIC_NODE_ENV === 'production' ? 60 : 1

export const NAVIGATION_REVALIDATE_INTERVAL_IN_SECONDS =
  process.env.NEXT_PUBLIC_NODE_ENV === 'production' ? 180 : 1

export const PRODUCT_STATUSES = {
  NOT_PUBLISHED: 'NOT_PUBLISHED',
  PUBLISHED: 'PUBLISHED',
  ARCHIVED: 'ARCHIVED',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
} as const

export const NAVIGATION_STATUSES = {
  PUBLISHED: 'PUBLISHED',
  NOT_PUBLISHED: 'NOT_PUBLISHED',
} as const

export const DISPLAY_NAVIGATION_STATUSES = {
  PUBLISHED: 'PUBLISHED',
  DRAFT: 'DRAFT',
} as const

export const PRODUCT_VARIANT_STATUSES = {
  NOT_PUBLISHED: 'NOT_PUBLISHED',
  PUBLISHED: 'PUBLISHED',
  ARCHIVED: 'ARCHIVED',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
} as const

export const COLLECTION_SORTER_OPTIONS = {
  RELEVANCE: {
    label: 'Relevance',
    value: {
      field: COLLECTION_SORTER_FIELDS.POSITION_INDEX_WEIGHT,
      direction: SORTER_DIRECTION.ASC,
    },
  },
  NEWEST: {
    label: 'Newest',
    value: {
      field: COLLECTION_SORTER_FIELDS.FIRST_PUBLISHED_AT_UTC,
      direction: SORTER_DIRECTION.DESC,
    },
  },
  PRICE_ASC: {
    label: 'Price: Low to High',
    value: {
      field: COLLECTION_SORTER_FIELDS.PRICE,
      direction: SORTER_DIRECTION.ASC,
    },
  },
  PRICE_DESC: {
    label: 'Price: High to Low',
    value: {
      field: COLLECTION_SORTER_FIELDS.PRICE,
      direction: SORTER_DIRECTION.DESC,
    },
  },
} as const

export const TWO_WEEKS_IN_DAYS = 14

export const APPLY_COUPON_ERROR_MESSAGE = 'This code is no longer valid'

export const REFUND_TYPES = {
  CASH: 'CASH',
  CREDITS: 'CREDITS',
} as const

export const FILTER_VIEW_COMPONENT_NAMES = {
  AVAILABILITY: 'AvailabilityFilter',
  PRICE: 'PriceFilter',
  TAG: 'TagFilter',
  SORT: 'Sort',
  COLLAPSIBLE_TREE_SELECT: 'CollapsibleTreeSelect',
} as const

export const COMPOSED_FILTERS_CONFIGURATION_NAMES = {
  SORT: 'sort',
  SESSION_LENGTH: 'sessionLength',
  PRICE: 'price',
  BRANDS: 'brands',
  TAGS: 'tags',
  REGIONS: 'regions',
  CATEGORIES: 'categories',
  AVAILABILITY: 'availability',
  COLLECTIONS: 'collections',
  STATUSES: 'statuses',
  VARIANT_STATUSES: 'variantStatuses',
} as const

export const PAYMENT_OPTIONS = {
  APPLE_PAY: 'applePay',
  GOOGLE_PAY: 'googlePay',
  CARD: 'card',
} as const

export const ORDER_STATUS = {
  UNPAID: 'UNPAID',
  PROCESSING: 'PROCESSING',
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
} as const

export const ORDER_ITEM_STATUS = {
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
  SHIPPED: 'SHIPPED',
  DELIVERED: 'DELIVERED',
  CANCELLED: 'CANCELLED',
} as const

export const ARTA_SERVICE_TYPE = {
  TRANSPORT: 'transport',
  HANDLING: 'handling',
} as const

export const ORDER_SHIPMENT_PREFIX = 'OrderShipment'
export const CART_SHIPMENT_PREFIX = 'CartShipment'

export const SHIPMENT_RESOLVE_TYPES = {
  ARTA_SHIPMENTS: 'ArtaQuote',
  VENDOR_SHIPPING_SHIPMENTS: 'VendorShipping',
  FREIGHT_CLUB_SHIPMENTS: 'FreightClubQuote',
} as const

export const CART_SHIPMENT_TYPES = {
  [SHIPMENT_RESOLVE_TYPES.ARTA_SHIPMENTS]: `${CART_SHIPMENT_PREFIX}${SHIPMENT_RESOLVE_TYPES.ARTA_SHIPMENTS}`,
  [SHIPMENT_RESOLVE_TYPES.FREIGHT_CLUB_SHIPMENTS]: `${CART_SHIPMENT_PREFIX}${SHIPMENT_RESOLVE_TYPES.FREIGHT_CLUB_SHIPMENTS}`,
  [SHIPMENT_RESOLVE_TYPES.VENDOR_SHIPPING_SHIPMENTS]: `${CART_SHIPMENT_PREFIX}${SHIPMENT_RESOLVE_TYPES.VENDOR_SHIPPING_SHIPMENTS}`,
}

export const ORDER_SHIPMENT_TYPES = {
  [SHIPMENT_RESOLVE_TYPES.ARTA_SHIPMENTS]: `${ORDER_SHIPMENT_PREFIX}${SHIPMENT_RESOLVE_TYPES.ARTA_SHIPMENTS}`,
  [SHIPMENT_RESOLVE_TYPES.FREIGHT_CLUB_SHIPMENTS]: `${ORDER_SHIPMENT_PREFIX}${SHIPMENT_RESOLVE_TYPES.FREIGHT_CLUB_SHIPMENTS}`,
  [SHIPMENT_RESOLVE_TYPES.VENDOR_SHIPPING_SHIPMENTS]: `${ORDER_SHIPMENT_PREFIX}${SHIPMENT_RESOLVE_TYPES.VENDOR_SHIPPING_SHIPMENTS}`,
}

export const VENDOR_SHIPPING_PREFIX = 'vendorShipping'

export const COMMISSION_TYPES = {
  EXPERT_COLLECTION_PAGE: 'EXPERT_COLLECTION_PAGE',
  EXPERT_RECOMMENDATION_CAROUSEL: 'EXPERT_RECOMMENDATION_CAROUSEL',
  EXPERT_EMAIL: 'EXPERT_EMAIL',
  EXPERT_SHOWROOM_IMPROVED_ATTRIBUTION: 'EXPERT_SHOWROOM_IMPROVED_ATTRIBUTION',
} as const

export const SHOWROOM_CHECKOUT_STORAGE_KEY = 'showroom-checkout-form-values-v1'

export const COOKIE_POLICY_SLUG = 'cookie-policy'
export const RETURN_POLICY_SLUG = 'marketplace-return-policy'
export const PURCHASE_TERMS_SLUG = 'purchase-terms-and-conditions'
export const ACCESSIBILITY_SLUG = 'accessibility'
export const PROCUREMENT_SLUG = 'procurement-faq'

export const ReactRefPropType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({ current: PropTypes.any }),
])

export const CONTACT_US_MODAL_TYPES = {
  INFO_LISTING_WIDGET: 'InfoListingWidget',
  FOOTER: 'Footer',
} as const

export const DISCOUNT_TYPES = {
  COUPON_AMOUNT: 'COUPON_AMOUNT',
  COUPON_PERCENTAGE: 'COUPON_PERCENTAGE',
  CREDIT_AMOUNT: 'CREDIT_AMOUNT',
  PROMO_CODE_PERCENTAGE: 'PROMO_CODE_PERCENTAGE',
  SESSION_PERCENTAGE: 'SESSION_PERCENTAGE',
  SHIPMENT_SUBSIDY: 'SHIPMENT_SUBSIDY',
} as const

export const PRODUCT_AVAILABILITY = {
  IN_STOCK: 'IN_STOCK',
  MADE_TO_ORDER: 'MADE_TO_ORDER',
  ENQUIRE: 'ENQUIRE',
  SOLD_OUT: 'SOLD_OUT',
} as const

export type ProductAvailability =
  (typeof PRODUCT_AVAILABILITY)[keyof typeof PRODUCT_AVAILABILITY]

const SCHEMA_URL = 'https://schema.org/'

export const SCHEMA_TYPES = {
  THING: `${SCHEMA_URL}Thing`,
  CREATIVE_WORK: `${SCHEMA_URL}CreativeWork`,
  ITEM_LIST: `${SCHEMA_URL}ItemList`,
  LIST_ITEM: `${SCHEMA_URL}ListItem`,
} as const

export const SCHEMA_PROPS = {
  NAME: 'name',
  ALTERNATE_NAME: 'alternateName',
  IMAGE: 'image',
  URL: 'url',
  POSITION: 'position',
} as const

export const KLAVIYO_ACCESSIBILITY_REPLACEMENT = {
  'POPUP Form': 'Promotional popup window entered',
  'Open Form': 'Button, open get ten percent off promotional popup window',
} as const

export const USER_TYPES = {
  GUEST: 'GUEST',
  CLIENT: 'CLIENT',
  EXPERT_USER: 'EXPERT_USER',
} as const

export const STRAPI_WIDGETS = {
  PUBLIC_LAYOUT_MENU_WIDGET: 'widgets.public-layout-menu-widget',
  FEATURE_BAR_WIDGET: 'widgets.feature-bar-widget',
  EXPERT_TRADE_APPLICATION_FORM_WIDGET:
    'widgets.expert-trade-application-form-widget',
  HERO_WIDGET: 'widgets.hero-widget',
  DOUBLE_PANE_WIDGET: 'widgets.double-pane-widget',
  TRADE_BENEFITS_WIDGET: 'widgets.trade-benefits-widget',
} as const
